body,
html {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  background-color: #18181c;
}

/*FONT-FAMILY*/
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Black.ttf");
  font-weight: 900;
}

::selection {
  background-color: #8054ff !important;
  color: #fff;
}

.bg-black {
  background-color: #000 !important;
}

.bg-light {
  background-color: #000 !important;
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link {
  color: #9e9e9e;
}

.navbar-nav {
  margin-left: auto;
  align-items: center;
}

.navbar {
  padding: 40px 20px 10px 20px;
}

.livePreview {
  width: 100%;
  height: 100%;
}

.btn-danger {
  background: linear-gradient(132deg, #ff9898, #8054ff);
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 12px 20px;
}

.fs-64 {
  font-size: 64px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-20 {
  margin-top: 20px;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fs-18 {
  font-size: 18px;
}

.text-grey {
  color: #9e9e9e !important;
}

.mt-80 {
  margin-top: 80px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.left-100 {
  position: absolute;
  left: 100px;
  top: 400px;
}

.right-100 {
  position: absolute;
  right: 100px;
  top: 300px;
}

.bg-pink {
  background-image: url(../imgs/Subtract.png);
  width: 100%;
  height: 560px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -380px;
}

.fs-48 {
  font-size: 48px;
}

.grey-box {
  background-color: #18181c;
  width: 100%;
  padding: 50px 30px;
  border-radius: 20px;
}

.box-grey {
  background-color: #18181c;
  width: 100%;
  padding: 5px 20px 0px 20px;
  border-radius: 5px;
}

.square {
  background-color: #222228;
  padding: 30px 20px;
  border-radius: 30px;
  transition: ease 0.8s;
}

.square:hover {
  background: linear-gradient(132deg, #ff9898, #8054ff);
  transition: ease 0.8s;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.mt-180 {
  margin-top: 180px;
}

.rotate {
  animation: rotate 5s linear reverse infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

.bg-grey {
  background-color: #18181c;
  width: 100%;
  padding: 100px 20px 300px 20px;
}

.pink-bg {
  background: linear-gradient(132deg, #ff9898, #8054ff);
  width: 100%;
  border-radius: 40px;
  padding: 90px 0px 20px 80px;
  margin-top: -300px;
}

.btn-dark {
  background-color: #000 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 12px 20px;
}

.form-control {
  color: #9e9e9e !important;
  background-color: #18181c !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
  padding: 20px;
}

.h-10 {
  height: calc(1.5em + 0.75rem + 20px);
}

textarea {
  resize: none;
}

.top-480 {
  position: absolute;
  top: 480px;
  left: 480px;
}

.top--50 {
  position: absolute;
  top: -50px;
  left: -50px;
}

.fs-16 {
  font-size: 16px;
}

.pb-100 {
  padding-bottom: 100px;
}

.footer {
  background-color: #000;
  padding: 30px 20px;
}

.fs-14 {
  font-size: 14px;
}

.dn-1 {
  display: none;
}

.fs-12 {
  font-size: 12px;
}

.vertical-line {
  width: 1px;
  height: 40px;
  background-color: #3d3d49;
}

.p-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.lg-vh {
  height: auto;
}

.dn-md {
  display: none;
}

.vh-101 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vh-102 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-pink {
  background: linear-gradient(132deg, #ff9898, #8054ff);
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.background {
  background-image: url(../imgs/Mask%20group.png);
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 30px;
}

.fs-40 {
  font-size: 40px;
}

.box-greyish {
  background-color: #18181c;
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.background-1 {
  background-image: url(../imgs/Mask%20group%20copy.png);
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 30px;
}

.pa {
  position: absolute;
  bottom: 40px;
}

.b-black {
  background: #000 !important;
}

.bg-pink1 {
  background-image: url(../imgs/Subtract-welcome.png);
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

iframe {
  /* border: 4px solid #8054ff; */
  /* border-radius: 40px; */
  margin-top: -200px;
}

.vh-103 {
  height: 100vh;
}

.camera {
  width: 100%;
  height: 260px;
  background-color: #000;
}

.btn-area {
  width: 100%;
  padding: 20px;
  background-color: #171d22;
}

.p-20 {
  padding: 20px;
}

.b-navy {
  background: #12a9fe1a !important;
}

.mt--200 {
  margin-top: -150px;
}

.bg-gradient {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
}

.box-black {
  width: 100%;
  background-color: #000;
  padding: 30px 40px;
  border-radius: 20px;
}

.form-select {
  color: #9e9e9e !important;
  background-color: #18181c !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
  background-image: url(../imgs/select.svg) !important;
  padding-left: 20px;
}

select option {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
}

::placeholder {
  color: #9e9e9e !important;
}

.upload-box {
  color: #9e9e9e !important;
  background-color: #18181c !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
  background-image: url(../imgs/upload.svg) !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding: 10px 20px;
}

.offer-box {
  color: #9e9e9e !important;
  background-color: #18181c !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px;
  background-image: url(../imgs/offers.svg) !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding: 10px 20px;
}

.top-18 {
  position: absolute;
  top: 18px;
  right: 13px;
}

.vh-104 {
  height: 100vh;
}

.fs-41 {
  font-size: 40px;
}

.fs-21 {
  font-size: 20px;
}

.modal-dialog {
  max-width: 80%;
}

.modal-content {
  background-color: #18181c;
  color: #fff;
  padding: 40px;
  border-image: linear-gradient(#ff9898, #8054ff) 132;
  border-width: 1px;
  border-style: solid;
}

.modal-footer {
  border-top: 0px;
}

.modal-header {
  border-bottom: 0px;
}

.modal-black-box {
  width: 100%;
  background-color: #000;
  padding: 20px;
}

input[type="checkbox"] {
  position: relative;
  width: 50px;
  height: 25px;
  -webkit-appearance: none;
  appearance: none;
  background: #bdbdbf;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  margin-top: 10px;
}

input[type="checkbox"]::before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

input[type="checkbox"]:checked {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
}

.modal-black-box input {
  /* color: #8054ff !important; */
}

.bg-pink-vertical {
  background-image: url(../imgs/Subtract%20copy.png);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-greyish {
  background-color: #18181c;
}

.text-greyish {
  color: #bdbdbf;
}

.bg-greyish .active {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  width: 100%;
  padding: 20px;
  text-align: left;
  border: 0px;
  outline: none;
  box-shadow: none;
  color: #fff;
}

.inactive {
  background-color: transparent;
  width: 100%;
  padding: 20px;
  text-align: left;
  border: 0px;
  outline: none;
  box-shadow: none;
  color: #bdbdbf;
}

.inactive:hover {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  width: 100%;
  padding: 20px;
  text-align: left;
  border: 0px;
  outline: none;
  box-shadow: none;
  color: #fff;
}

.filter-box {
  background-color: #000;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
}

.btn-secondary {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 10px;
  border: 1px solid #262525 !important;
  padding: 10px;
  width: 100%;
  box-shadow: none !important;
  outline: none !important;
  z-index: 1;
}

.dropdown-menu.show {
  width: 100%;
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  border-radius: 0px 0px 16px 16px;
  border: 1px solid #fff;
  transform: translate(0px, 45px) !important;
}

.dropdown-item {
  color: #fff !important;
  white-space: normal;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent !important;
}

.job-box {
  background-color: #000;
  border-radius: 16px;
}

.job-box-header {
  border-radius: 0px 16px;
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  padding: 10px;
  width: 120px;
  color: #fff;
  text-align: center;
  margin-left: auto;
}

.job-box-footer {
  width: 100%;
  background-color: #171d22;
  border-radius: 0px 0px 16px 16px;
  padding: 20px 20px 10px 20px;
  position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.text-darkgrey {
  color: #747373;
  word-wrap: break-word;
}

.btn-close {
  width: 34px;
  height: 34px;
  opacity: 1 !important;
}

.btn-outline-danger {
  border: 1px solid #8054ff;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 12px 20px;
  color: #fff;
}

.btn-outline-danger:hover {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
      border: 1px solid transparent!important;
  outline: none !important;
}

.border-top {
  border-top: 1px solid #262525 !important;
}

.border-right {
  border-right: 1px solid #262525 !important;
  padding-right: 40px;
}

.btn-primary {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  border: none !important;
  outline: none !important;
  top: 200px;
  position: fixed;
  left: -45px;
  width: 80px;
  border-radius: 0px 100px 100px 0px;
  font-size: 30px;
  z-index: 1;
  padding: 10px;
  color: #fff !important;
  text-align: right;
}

.w-20 {
  width: 20px;
}

.mt--10 {
  margin-top: -10px;
}

.p-24 {
  padding: 24px 30px;
}

.chat-active {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #f694a0;
    border-radius: 10px;
    margin: 0 !important;
    margin-bottom: 10px !important;
}

.bg-grad1 {
  color: #8054ff !important;
}

.bg-grad1:hover {
  background: linear-gradient(132deg, #ff9898, #8054ff) !important;
  color: #fff !important;
}

.w-40 {
  width: 40px;
}

.bottom-60 {
  position: absolute;
  bottom: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.text-gradient {
  background: linear-gradient(180deg, #ff9898, #8054ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.p-60 {
  padding: 30px 60px;
}

.fs-26 {
  font-size: 24px;
}

.top-80 {
  position: absolute;
  right: 15px;
  top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.mt-38 {
  margin-top: 38px;
}

.fa-user-group {
  font-size: 18px;
}

.border-purple {
  border: 1px solid #8054ff;
}

.table > :not(caption) > * > * {
  background-color: #000;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #20262c;
}

.fw-400 {
  font-weight: 400;
}

table {
  text-align: center;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border: none !important;
}

.top-15 {
  position: absolute;
  right: 15px;
  top: 15px;
}

.top-16 {
  position: absolute;
  right: 15px;
  top: 15px;
}

hr {
  border-top: 1px solid #404047;
  opacity: 1;
  margin-top: 30px;
}

.nav-pills .nav-link {
  background-color: #18181c !important;
  border: 0;
  color: #9e9e9e !important;
  padding: 10px 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: linear-gradient(180deg, #ff9898, #8054ff) !important;
  color: #fff !important;
}

.brdr-left {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.brdr-right {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.w-70 {
  width: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -85px;
}

.p-sticky {
  position: sticky;
  top: 0px;
}

.no-arrow {
  -moz-appearance: textfield;
}

.no-arrow::-webkit-inner-spin-button {
  display: none;
}

.text-purple {
  color: #8054ff !important;
}

.fs-31 {
  font-size: 30px;
  position: relative;
  top: 32px;
}

/*responsiveness*/

@media (max-width: 2560px) {
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
  .p-80 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .dn-md {
    display: block;
  }
}

@media (max-width: 1280px) {
  .top-480 {
    left: 350px;
  }
}

@media (max-width: 1200px) {
  .fs-48 {
    font-size: 40px;
  }

  .square {
    padding: 20px;
  }

  .top-480 {
    left: 300px;
  }

  .fs-40 {
    font-size: 32px;
  }
}

@media (max-width: 1153px) {
  .col-lg-1 {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

@media (max-width: 991px) {
  .navbar-toggler {
    background: linear-gradient(132deg, #ff9898, #8054ff);
  }

  .square {
    padding: 30px 20px;
  }

  .mt-21 {
    margin-top: 20px;
  }

  .mt-31 {
    margin-top: 30px;
  }

  .fs-20 {
    font-size: 16px;
  }

  .fs-48 {
    font-size: 28px;
  }

  .pink-bg {
    padding: 30px 0px 20px 30px;
    margin-top: -240px;
  }

  .fs-64 {
    font-size: 46px;
  }

  .bg-pink {
    height: 380px;
    margin-top: -250px;
  }

  .border-right {
    border-right: none !important;
    padding-right: 0px;
  }

  .center {
    text-align: center !important;
  }

  .dn {
    display: none;
  }

  .dn-1 {
    display: block;
  }

  .mt-180 {
    margin-top: 80px;
  }

  .bg-grey {
    padding: 50px 20px 230px 20px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .sm-w-100 {
    width: 100%;
  }

  .col-lg-1 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .vh-102 {
    height: auto;
    padding: 80px 20px;
  }

  .fs-40 {
    font-size: 26px;
  }

  .pa {
    left: 50%;
    margin-left: -60px;
  }

  .vh-104 {
    height: auto;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .pr-25 {
    padding-right: 0px;
  }

  .p-60 {
    padding: 30px 30px;
  }

  .bg-greyish {
    padding: 0px 20px;
  }

  .offcanvas .active {
    background: linear-gradient(132deg, #ff9898, #8054ff) !important;
    width: 100%;
    padding: 20px;
    text-align: left;
    border: 0px;
    outline: none;
    box-shadow: none;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .fs-48 {
    font-size: 22px;
  }

  .mt-40 {
    margin-top: 20px;
  }

  .fs-18 {
    font-size: 16px;
  }

  .fs-20 {
    font-size: 16px;
  }

  .fs-64 {
    font-size: 28px;
  }

  .fs-40 {
    font-size: 18px;
  }

  .fs-24 {
    font-size: 16px;
  }

  .dropdown-item {
    font-size: 12px;
  }

  .p-80 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 576px) {
}

@media (max-width: 414px) {
  .offcanvas.offcanvas-start {
    width: 300px;
  }
}

@media (max-width: 375px) {
}

@media (max-width: 320px) {
}

/*height wise responsiveness*/
@media (min-height: 1024px) {
}

.basic-multi-select > div {
  background-color: #18181c;
  text-align: left;
  color: #9e9e9e !important;
}

.select__menu > div > div:hover {
  background-color: #bb2d3b;
}

.select__multi-value__remove > svg {
  background-color: hsl(0, 0%, 80%);
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #bb2d3b; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9e9e9e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(132deg, #ff9898, #8054ff);
  border-radius: 10px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@media (max-width: 600px) {
  body {
    overflow-y: auto;
  }
}

.sendButtonDisabled {
  cursor: not-allowed !important;
}

.chatContentItem {
  display: flex;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  margin: 0;
}

.chatContentItem:hover {
  background: linear-gradient(132deg, #ff9898, #8054ff);
}

.hidescroll {
  overflow-x: hidden !important;
}


.job-box {
    background-color: #000;
    border-radius: 16px;
   
    position: relative;
 
}

.modal-black-box .fs-14.text-white {
    height: 20px;
}


.job-box video {
    object-fit: cover;
}
.height-set {
    padding: 0 40px;
    min-height: 420px;
}
.modal-content video {
    object-fit: cover;
}

.send-img {
    width: 500px!important;
    height: 400px!important;
    object-fit: cover;
}

.modal-content.signout-pop {
  width: 65%;
}

.modal-content.signout-pop h5.modal-title {
  width: 100%;
  text-align: center;
}


.modal-content.signout-pop img.mt-20 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin: auto;
}
.verified-btn {
  padding: 10px 70px;
}

.logout-popup {
  z-index: 11111;
}